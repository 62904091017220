import React, {FC} from "react";
import {useTranslation} from "react-i18next";

export const ProductDetailsDescription: FC<{
	description: string,
	bestWithDescription?: string,
	awardsDescription?: string
}> = ({description, bestWithDescription, awardsDescription}) => {
	const {t} = useTranslation();
	
	return (
		<div>
			<p>
				<strong>{`${t("COMMON_WORDS.DESCRIPTION")}:`}</strong> {description?.split("<br/>").map((p, index) => index === 0 ? <>{p}<br/><br/></> :
				<p>{p}</p>)}
			</p>
			{bestWithDescription &&
        <p>
          <strong>{`${t("COMMON_WORDS.BEST_WITH")}:`}</strong> {bestWithDescription?.split("<br/>").map((p, index) => index === 0 ? <>{p}<br/><br/></> :
					<p>{p}</p>)}
        </p>
			}
			{awardsDescription &&
        <p>
          <strong>{`${t("COMMON_WORDS.AWARDS")}:`}</strong> {awardsDescription?.split("<br/>").map((p, index) => index === 0 ? <>{p}<br/><br/></> :
					<p>{p}</p>)}
        </p>
			}
		</div>
	)
};