import React from "react";
import HomeProductsList from "../../../commons/components/product/HomeProductsList";
import ProductItem from "../../../commons/components/product/ProductItem";
import {ShopWine} from "./OurWinesContainer";
import {FileUtils} from "../../../commons/shared/Utils";

export const HomeWinesListComponent: React.FC<{ randomWines: ShopWine[] }> = ({
                                                                                                            randomWines
                                                                                                        }) => {

    return (
        <HomeProductsList>
            {
                randomWines.map(wine => {
                    return <ProductItem imgUrl={FileUtils.getBottleImage(wine.shopImage)}
                                        name={wine.variety}
                                        name2={wine.name}
                                        productLink={`/wina/karta_win/${wine.id}`}
                                        addToCardLink={""}
                                        key={wine.id}
                                        badge={wine.badge}
                                        price={wine.price}
                                        alt={wine.alt}
                                        year={wine.year}
                    />
                })
            }
        </HomeProductsList>
    )
};