import React from "react";

interface Props {
    children: any
    columns?: number;
}

export const ProductsList: React.FC<Props> = ({children, columns = 4}) => {

    return (
        <div className="vc_column-inner ">
            <div className="wrap">
                <div className={"woocommerce columns-" + columns}>
                    <ul className="products">
                        {children}
                    </ul>
                </div>
            </div>
        </div>
    )
};