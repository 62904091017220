import React from "react";
import { Event } from "../../../MainApp/EventsApp/EventComponent";
import events from "../../../model/data/events.json";
import AboutWine from "../../../MainApp/HomeApp/AboutWine";

const NextEvents = () => {

    const event: Event = events.filter(e => !e.archived && e?.homePage === true)?.[0];

    if (!event) return null;
    return (
        <AboutWine title={event.title}
                   subTitle={event.descriptionParagraphs[0]}
                   text={event.descriptionParagraphs[1]}
                   imgSrc={event.backgroundImage}
                   buttonLabel={"Sprawdź"}
                   path={`/wydarzenia/${event.id}`}
        />
    )
};

export default NextEvents;