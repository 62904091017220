import React from "react";
import {VoucherPage} from "./VoucherPage";
import {AppWrapper} from "../../commons/components/wrappers/AppWrapper";

export const VoucherApp = () => {

    return (
        <AppWrapper.Default title={"Vouchery podarunkowe"} fullWideImage={"vouchery-na-swieta.jpg"}>
            <VoucherPage/>
        </AppWrapper.Default>
    )
};