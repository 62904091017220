const getImage = (fileName?: string, fallback?: string) => {
    return `${process.env.PUBLIC_URL}/images/${fileName || fallback}`
}

export const FileUtils = {
    getImage: getImage,
    getBottleImage: (fileName?: string) => getImage(fileName, 'bottle.png')
}

type List = {id: number}

const getListById = (list: List[], listOfId: number[]) => {
    return list.filter(el => listOfId.some(number => number === el.id))
}

export const ListUtils = {
    getListById: getListById
}

