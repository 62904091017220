import React, {FC} from "react";
import {GoogleMapContact} from "../../../commons/components/GoogleMapContact";
import {RowContent} from "../../../commons/components/RowContent";
import {CommonWrapper} from "../../../commons/components/wrappers/CommonWrapper";
import {useTranslation} from "react-i18next";

export const VoucherPage: FC<{}> = () => {

    const {t} = useTranslation();

    return (
        <div className="page_content_wrap scheme_default">
            <CommonWrapper>
                <RowContent icon={"icon-grape-leafs"}
                            title={t('VOUCHER.PAGE.T1')}
                            subtitle={t('VOUCHER.PAGE.SBT1')}
                            paragraphs={[t('VOUCHER.PAGE.P1')]}
                />
                <RowContent icon={"icon-grape-leafs"}
                            title={t('VOUCHER.PAGE.T2')}
                            subtitle={t('VOUCHER.PAGE.SBT2')}
                            paragraphs={[t('VOUCHER.PAGE.P2')]}
                />
                <RowContent icon={"icon-grape-leafs"}
                            title={t('VOUCHER.PAGE.T3')}
                            subtitle={t('VOUCHER.PAGE.SBT3')}
                            paragraphs={[
                              t('VOUCHER.PAGE.P3.1'),
                              t('VOUCHER.PAGE.P3.2'),
                              t('VOUCHER.PAGE.P3.3'),
                              t('VOUCHER.PAGE.P3.4'),
                              t('VOUCHER.PAGE.P3.5')
                            ]}
                />
            </CommonWrapper>
            <GoogleMapContact/>
        </div>
    );
};
