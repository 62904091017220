import React from "react";
import wines from "../../../model/data/wines.json"
import {ProductInList} from "./ProductInList";
import {Wine} from "../../../model/Wine";
import {useTranslation} from "react-i18next";
import {FileUtils} from "../../../commons/shared/Utils";
import ProductItem from "../../../commons/components/product/ProductItem";
import {ProductsList} from "../../../commons/components/product/ProductList";
import useToggle from "../../../commons/shared/useToggle";
import {NavLink} from "react-router-dom";

export const WineList = () => {

    const {t} = useTranslation();

    const wineInLists = (wines as Wine[]).filter(wine => wine.available);
    const [isListView, toggleView] = useToggle(false);

    return (
        <>
            <div className="wrap laon_wine_house_shop_mode_buttons" onClick={toggleView}>
                <NavLink to={""} className={isListView ? "woocommerce_list icon-th-list" : "woocommerce_thumbs icon-th"}
                         title={t(`WINE.LIST.SHOW_${isListView ? "THUMBS" : "LIST"}`)}
                         onClick={e => e.preventDefault()}> {t('WINE.LIST.CHANGE_VIEW')} </NavLink>
            </div>
            <div className="vc_empty_space  height_small"/>
            {
                isListView ?
                    wineInLists?.map((wine, index) => {
                        return <ProductInList imgUrl={FileUtils.getBottleImage(wine.detailsImage)}
                                              year={wine.year}
                                              variety={wine.variety}
                                              description={wine.description}
                                              type={t(`WINE.TASTE.${wine.type}`)}
                                              key={wine.id}
                                              invert={index % 2 === 0}
                                              id={wine.id}
                        />
                    })
                    :
                    <ProductsList>
                        {
                            wineInLists.map(wine => {
                                return <ProductItem imgUrl={FileUtils.getBottleImage(wine.shopImage)}
                                                    name={wine.variety}
                                                    name2={`${t("WINE.COLOR." + wine.color)} ${t("WINE.TASTE." + wine.type)}`}
                                                    productLink={`/wina/karta_win/${wine.id}`}
                                                    addToCardLink={""}
                                                    key={wine.id}
                                                    badge={wine.badge}
                                                    price={wine.price}
                                                    alt={wine.alt}
                                                    year={wine.year}
                                />
                            })
                        }
                    </ProductsList>
            }
        </>
    )
};