import React, {FC} from "react";
import {AppWrapper} from "../../../commons/components/wrappers/AppWrapper";
import {CommonWrapper} from "../../../commons/components/wrappers/CommonWrapper";
import {Event, EventComponent} from "../EventComponent";
import events from "../../../model/data/events.json"
import {useParams} from "react-router-dom";

export const EventPage: FC<{}> = () => {

    const {eventId} = useParams<{eventId: string}>();

    const eventPageList: Event[] = events;
    const eventToShow: Event | undefined = eventPageList.find(event => event.id === eventId)

    return (
        <AppWrapper.Article title={"Wydarzenia 2024"}>
            <CommonWrapper>
                <EventComponent eventPage={eventToShow || eventPageList[0]}/>
            </CommonWrapper>
        </AppWrapper.Article>
    );
};
