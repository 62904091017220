import React, { FC } from "react";
import MenuItem from "./MenuItem";
import MenuSection from "./MenuSection";
import { useScrollContext } from "../../../MainApp/HomeApp/ScrollContext";
import { pageSettings } from "../../../../pageConfig";

const Menu: FC<{show?: boolean}> = ({show = true}) => {

    const {scrollToTop} = useScrollContext();

    return show ? (
        <MenuSection>
            <MenuItem title={"START"} link={"/"} scrollToRef={() => scrollToTop()}/>
            <MenuItem title={"WINNICA"} link={"/winnica"}/>
            <MenuItem title={"SZLAK WINNY"} link={"/szlakwinny"}/>
            { !pageSettings.hideEvents ? <MenuItem title={"WYDARZENIA"} link={"/wydarzenia"}/> : null }
            <MenuItem title={"JAK TWORZYMY"} link={"/o_nas"}/>
            <MenuItem title={"WINA"} link={"/wina"} hasChildren>
                <MenuItem title={"KARTA WIN"} link={"/wina/karta_win"}/>
                <MenuItem title={"SPRZEDAŻ"} link={"/wina/sprzedaz"}/>
                <MenuItem title={"DEGUSTACJA I ZWIEDZANIE"} link={"/wina/degustacja"}/>
                <MenuItem title={"NAGRODY"} link={"/wina/nagrody"}/>
            </MenuItem>
            <MenuItem title={"VOUCHERY"} link={"/voucher"}/>
            <MenuItem title={"KONTAKT"} link={"/kontakt"}/>
        </MenuSection>
    ) : null;
}

export default Menu;
