import React, {useRef, useState} from "react";
import "../../../../css/custom.css"
import useClickOutside from "../../../commons/shared/useClickOutside";
import {Wine} from "../../../model/Wine";
import wines from "../../../model/data/wines.json"
import {DotPopupsProps} from "./VineyardTopComponentPresentation";
import {ListUtils} from "../../../commons/shared/Utils";
import {pageSettings} from "../../../../pageConfig";
import {Event} from "../../../commons/Tracking";

export const withWinesPopupsHOC = <T extends DotPopupsProps>(WrappedComponent: React.ComponentType<T>) => (ownProps: Omit<T, keyof DotPopupsProps>) => {
    const {wrapperRef} = useClickOutside(() => closePopup())

    const wineInLists = wines as Wine[];
    const winesInDots = ListUtils.getListById(wineInLists, pageSettings.dotComponent.wines);

    const [position, setPosition] = useState<{ x: number, y: number }>();

    const [imageLoaded, setImageLoaded] = useState(false);

    const [showPopup, setShowPopup] = useState(false);
    const showPopupRef = useRef(showPopup);
    showPopupRef.current = showPopup;


    const [selectedWine, setSelectedWine] = useState<Wine>();
    const [prevWineId, setPrevWineId] = useState<number>();
    const selectedWineRef = useRef(selectedWine);
    selectedWineRef.current = selectedWine;

    const onDotClick = (e: React.MouseEvent<HTMLElement>, wineId: number) => {
        Event("Exploring", `Click on DotComponent, wineId: #${wineId}`, "HOME_PAGE")

        if (prevWineId !== undefined && prevWineId !== selectedWineRef.current?.id) {
            return;
        }
        const newPosition = {x: verifyEdgePositions(e.clientX), y: e.clientY};
        setTimeout(() => {
            setShowPopup(!showPopup);
            setSelectedWine(wineInLists.find(wine => wine.id === wineId));
            setPosition(newPosition);
        }, 300)
    }

    const closePopup = () => {
        selectedWineRef.current && setPrevWineId(selectedWineRef.current?.id)
        if (showPopupRef.current) {
            setShowPopup(false);
            setTimeout(() => {
                setImageLoaded(false);
            }, 300)
        }
    };

    const verifyEdgePositions = (positionX: number) => {
        return checkLeftEdgePosition(checkRightEdgePosition(positionX));
    };

    const checkLeftEdgePosition = (positionX: number) => {
        return positionX - (positionX < 105 ? 0 : 105)
    };

    const checkRightEdgePosition = (positionX: number) => {
        return positionX - (positionX < window.innerWidth - 144 ? 0 : window.innerWidth - positionX)
    };

    const popupProps = {
        wrapperRef,
        showPopup,
        position,
        selectedWine,
        imageLoaded: imageLoaded || prevWineId === selectedWine?.id,
        onLoadImage: () => setImageLoaded(true)
    }

    const props = {
        popupProps,
        ...ownProps,
        onDotClick,
        winesInDots
    };

    return <WrappedComponent {...props as T} />
}