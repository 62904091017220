import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { GoogleMapContact } from "../../../commons/components/GoogleMapContact";
import { NavLink } from "react-router-dom";

interface Props {
    eventPage: Event;
}

export interface Event {
    id: string;
    archived: boolean,
    homePage?: boolean,
    home: boolean;
    backgroundImage: string;
    location: { lat: number, lng: number }
    title: string;
    eventDateStart: string;
    eventDateEnd: string;
    buttonNext: {
        label: string;
        path: string;
    };
    eventImage: string;
    descriptionParagraphs: string[];
    buttonCurrent: {
        label: string;
        path: string;
    };
    eventDescription: EventDescription;
}

interface EventDescription {
    details: {
        header: string;
        where: string;
        when: string;
        time: string;
        attractions: string;
    };
    organisers: {
        name: string[];
        phone?: string[];
        email?: string[];
        website: {
            uri: string,
            label: string;
        }[];
    }
}

export const EventComponent: FC<Props> = ({
                                              eventPage
                                          }) => {
    const { t } = useTranslation();

    const {
        id,
        title,
        eventDateStart,
        eventDateEnd,
        buttonNext,
        eventImage,
        descriptionParagraphs,
        buttonCurrent,
        eventDescription,
        location
    } = eventPage;

    return (
        <div className="content" style={{ paddingBottom: '25px' }}>
            <article id="post-0" className="post_item_single post_type_page post-0 page type-page status-draft">
                <div className="post_content entry-content">
                    <div id="tribe-events">
                        <div className="tribe-events-before-html"/>
                        <div id="tribe-events-content" className="tribe-events-single">
                            <p className=""><NavLink to={"/wydarzenia"}>{t('EVENTS.BUTTON.BACK_TO_ALL')}</NavLink></p>
                            <h1 className="tribe-events-single-event-title"> {title} </h1>
                            <div className="tribe-events-schedule tribe-clearfix">
                                <h2>
                                    <span className="tribe-event-date-start"> {eventDateStart} </span>{" "} -{" "}
                                    <span className="tribe-event-date-end"> {eventDateEnd} </span>
                                </h2>{" "}
                            </div>
                            <div id="tribe-events-header">
                                <h3 className="tribe-events-visuallyhidden">Nawigacja wydarzenia</h3>
                                <ul className="tribe-events-sub-nav">
                                    <li className="tribe-events-nav-previous"/>
                                    <li className="tribe-events-nav-next">
                                        <NavLink to={buttonNext?.path}
                                                 className="sc_button_hover_slide_top">{buttonNext?.label}
                                            <span>»</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div id="post-371"
                                 className="post-371 tribe_events type-tribe_events has-post-thumbnail tag-festival tribe_events_cat-festivals cat_festivals">
                                <div className="tribe-events-event-image"><img width={2340} height={1560}
                                                                               src={`images/${eventImage}`}
                                                                               className="attachment-full size-full wp-post-image"
                                                                               alt="event"/></div>
                                <div className="tribe-events-single-event-description tribe-events-content"
                                     style={{ color: 'grey' }}>
                                    {descriptionParagraphs.map(desc => {
                                        return <p dangerouslySetInnerHTML={{ __html: desc }} key={desc}/>
                                    })}
                                </div>
                                {buttonCurrent.path ?
                                    <div className="tribe-events-cal-links">
                                        <a className="tribe-events-gcal tribe-events-button sc_button_hover_slide_top"
                                           href={buttonCurrent.path}
                                           title={buttonCurrent.label}>
                                            {buttonCurrent.label}
                                        </a>
                                    </div> : null
                                }
                                <div
                                    className="tribe-events-single-section tribe-events-event-meta primary tribe-clearfix">
                                    <div className="tribe-events-meta-group tribe-events-meta-group-details">
                                        <h3 className="tribe-events-single-section-title"> {eventDescription.details.header} </h3>
                                        <dl>
                                            <dt>{t('EVENTS.WHERE')}:</dt>
                                            <dd>
                                                <span className="tribe-events-abbr updated published"
                                                      style={{ color: '#7a7a7a' }}>{eventDescription.details.where}</span>
                                            </dd>
                                            <dt>{t('EVENTS.WHEN')}:</dt>
                                            <dd>
                                                <span className="tribe-events-abbr"
                                                      style={{ color: '#7a7a7a' }}>{eventDescription.details.when}</span>
                                            </dd>
                                            <dt>{t('EVENTS.TIME')}:</dt>
                                            <dd>
                                                <span className="tribe-events-abbr"
                                                      style={{ color: '#7a7a7a' }}>{eventDescription.details.time}</span>
                                            </dd>
                                            <dt> {t('EVENTS.ATTRACTIONS')}:</dt>
                                            <dd>
                                                <span className="tribe-events-abbr"
                                                      style={{ color: '#7a7a7a' }}>{eventDescription.details.attractions}</span>
                                            </dd>
                                        </dl>
                                    </div>
                                    <div className="tribe-events-meta-group tribe-events-meta-group-organizer">
                                        <h3
                                            className="tribe-events-single-section-title">{eventDescription.organisers.name.length > 1 ? t('EVENTS.ORGANISERS') : t('EVENTS.ORGANISER')}:</h3>
                                        <dl>
                                            <dt style={{ display: "none" }}/>
                                            <dd className="tribe-organizer mb-0" style={{ color: '#7a7a7a' }}>
                                                {
                                                    eventDescription.organisers.name.map((value, index) => value + (index === eventDescription.organisers.name.length - 1 ? "" : ", "))
                                                }
                                            </dd>
                                            {
                                                eventDescription.organisers.phone &&
                                              <>
                                                <dt>{t('EVENTS.PHONE')}:</dt>
                                                <dd className="tribe-organizer-tel" style={{ color: '#7a7a7a' }}>
                                                    {
                                                        eventDescription.organisers.phone.map((value, index) => value + (index === (eventDescription.organisers.phone?.length || 1) - 1 ? "" : ", "))
                                                    }
                                                </dd>
                                              </>
                                            }
                                            {
                                                eventDescription.organisers.email &&
                                              <>
                                                <dt>{t('EVENTS.EMAIL')}:</dt>
                                                <dd className="tribe-organizer-tel" style={{ color: '#7a7a7a' }}>
                                                    {
                                                        eventDescription.organisers.email.map((value, index) => value + (index === (eventDescription.organisers.email?.length || 1) - 1 ? "" : ", "))
                                                    }
                                                </dd>
                                              </>
                                            }
                                            <dt>{t('EVENTS.WEBSITE')}:</dt>
                                            <dd className="tribe-organizer-url">
                                                {
                                                    eventDescription.organisers.website.map((value, index) => {
                                                        return <a href={value.uri}
                                                                  target="_self" key={value.uri}>{value.label + (index === eventDescription.organisers.website.length - 1 ? "" : ", ")}</a>
                                                    })
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div
                                    className="tribe-events-single-section tribe-events-event-meta secondary tribe-clearfix"/>
                                <GoogleMapContact location={location} zoom={17} onlyMap key={id}/>
                            </div>
                            <div id="tribe-events-footer" style={{ paddingBottom: '1rem' }}>
                                <h3 className="tribe-events-visuallyhidden">Event Navigation</h3>
                                <ul className="tribe-events-sub-nav">
                                    <li className="tribe-events-nav-previous"/>
                                    <li className="tribe-events-nav-next">
                                        <NavLink to={buttonNext?.path}
                                                 className="sc_button_hover_slide_top">{buttonNext?.label}
                                            <span>»</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="tribe-events-after-html"/>
                    </div>
                </div>
            </article>
        </div>
    );
};
