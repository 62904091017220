import events from "./winery/model/data/events.json";

export const pageStyle = {
    home: {
        mainImage: 'winnica_zachod2.jpg'
    }
} as const

interface IPageSettings {
    dotComponent: {
        wines: [number, number, number, number]
    },
    summerSeason: boolean,
    hideEvents: boolean
}

export const pageSettings: IPageSettings = {
    dotComponent: {
        wines: [1, 8, 15, 17]
    },
    summerSeason: false,
    hideEvents: !events.filter(e => !e.archived).length
}