import React, {FC} from "react";
import {useHistory} from "react-router-dom";
import {useResponsiveContext} from "../../../MainApp/HomeApp/ResponsiveContext";

interface Props {
    subtitle: string;
    title: string;
    button: {
        label: string;
        path: string;
    };
    backgroundImage: string;
}

export const CallToAction: FC<Props> = ({subtitle, title, button, backgroundImage}) => {

    const history = useHistory();
    const {isMobile} = useResponsiveContext();

    return (
        <div className="wpb_column vc_column_container column-6_12">
            <div className="vc_column-inner" style={{paddingBottom: (isMobile ? "30px" : "50px")}}>
                <div className="wpb_wrapper">
                    <div
                        className="sc_action sc_action_default"
                        data-slides-min-width={250}
                    >
                        <div className="sc_action_content sc_item_content">
                            <div
                                className="sc_action_item sc_action_item_default with_image"
                                style={{backgroundImage: `url(images/${backgroundImage})`}}
                            >
                                <div className="sc_action_item_mask"/>
                                <div className="sc_action_item_inner">
                                    <h6 className="sc_action_item_subtitle">
                                        <span>{subtitle}</span>
                                    </h6>
                                    <h2 className="sc_action_item_title">
                                        <span>{title}</span>
                                    </h2>
                                    <button
                                        type="button"
                                        className="sc_action_item_link sc_button_hover_slide_top"
                                        onClick={() => history.push(button.path)}
                                    >
                                        {button.label}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}