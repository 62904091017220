import React from "react";
import {EventsPage} from "./EventsPage";
import {AppWrapper} from "../../commons/components/wrappers/AppWrapper";

export const EventsApp = () => {

    return (
        <AppWrapper.Default title={"Wydarzenia 2024"}>
            <EventsPage/>
        </AppWrapper.Default>
    )
};